
















































import Vue from "vue";
import Package from "../../../package.json";
export default Vue.extend({
  name: "settings",
  metaInfo: {
    title: 'Configuración',
  },
  data () {
    return {
      version:Package.version
    }
  },
  methods:{
    openLink(url:string){
      window.open(url,'_blank')
    }
  }
});
